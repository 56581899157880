<template>
    <app-text-field
        type="number"
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        v-on="listenersWithoutInput"
        @input="onInput" />
</template>

<script>
import formFieldMixin from "./formFieldMixin";

export default {
    types: [
        "int",
        "decimal",
        "double",
        "single"
    ],
    mixins: [formFieldMixin],
    props: {
        value: {
            type: Number,
            default: null,
        },
    },
    computed: {
        listenersWithoutInput() {
            // eslint-disable-next-line no-unused-vars
            let { input, ...result } = this.$listeners;
            return result;
        }
    },
    methods: {
        onInput(event) {
            const value = parseFloat(event);
            this.$emit("input", isNaN(value) ? null : value);
        }
    }
};
</script>
