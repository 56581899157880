<template>
    <div>
        <a :href="url">{{ display }}</a>
    </div>
</template>

<script>
import { getLabel } from "@/features/schemas/services/labeller";

export default {
    types: [
        "file"
    ],
    props: {
        value: {
            type: Number,
            default: () => null,
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        display() {
            let lookup = this.options?.lookup;
            if (!lookup || !this.value) {
                return "";
            }

            let item = lookup[this.value.toString()];
            return getLabel("file", item);
        },
        url() {
            return `/api/Files/Download/${this.value}`;
        }
    }
};
</script>
