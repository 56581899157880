import { render, staticRenderFns } from "./App.vue?vue&type=template&id=24337914&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=24337914&prod&lang=scss&"
import style1 from "./App.vue?vue&type=style&index=1&id=24337914&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24337914",
  null
  
)

export default component.exports