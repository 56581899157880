<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
import { format } from "@/formatting/currencyFormatter";
export default {
    // TODO: Implement a way to match type based on entity key and field. i.e JobCompensationCell
    types: ["compensation"],
    props: {
        value: {
            type: [ Number ],
            default: null,
        },
        item: {
            type: [Object],
            default: null,
        }
    },
    computed: {
        display() {
            return format(this.value) + this.formatCompensation;
        },
        formatCompensation() {
            switch (this.item?.compensationMethod) {
                case "Hourly":
                    return " /hour"
                case "Shift":
                    return " /shift"
                case "Annual":
                    return " /year"
            }
            return ""
        }
    }
};
</script>
