<template>
    <div>
        <span>{{ formattedValue }}</span>
    </div>
</template>

<script>
import { format } from '@/formatting/enumFormatter';

export default {
    types: [
        "list`1",
        "array",
        "iEnumerable`1"
    ],
    props: {
        value: {
            type: [Array],
            default: null,
        }
    },
    computed: {
        formattedValue() {
            return this.value ? this.value.map(v => format(v)).join(', ') : '';
        }
    }
};
</script>
