import { camelToTitleCase } from "@/services/stringUtility"

export const types = [
    "enum"
];

export function format(value) {
    let result = camelToTitleCase(value);

    result = formatExperience(result);
    result = formatCommute(result);
    result = formatState(result);
    return result;
}

export function formatExperience(value) {
    switch (value) {
        case "Less Than One Year":
            return "<1 year";
        case "One To Two Years":
            return "1-2 years";
        case "Two To Five Years":
            return "2-5 years";
        case "Five To Ten Years":
            return "5-10 years";
        case "Ten Years Or More":
            return "10+ years";
        default:
            return value;
    }
}

export function formatCommute(value) {
    switch (value) {
        case "Fly In Fly Out":
            return "FIFO";
        case "Drive In Drive Out":
            return "DIDO";
        default:
            return value;
    }
}

export function formatState(value) {
    switch (value) {
        case "Australian Capital Territory":
            return "ACT";
        case "New South Wales":
            return "NSW";
        case "Northern Territory":
            return "NT";
        case "Queensland":
            return "QLD";
        case "South Australia":
            return "SA";
        case "Tasmania":
            return "TAS";
        case "Victoria":
            return "VIC";
        case "Western Australia":
            return "WA";
        default:
            return value;
    }
}
