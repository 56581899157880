import Vue from "vue";
import Vuex from "vuex";

import router from "@/routes";

import { alert } from "./alertModule";
import { authentication } from "./authenticationModule";
import { schema } from "./schemaModule";
import { localisation } from "./localisationModule";
import { drawer } from "./drawerModule";
import { load } from "./loadModule.js";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        drawer,
        schema,
        localisation,
        load
    },
    state: {
        // Adding router to the root store to support reactivity in Vuex getters (currentRoute).
        router: router
    },
    getters: {
        schema: state => state.schema.schema,
    },
});

// Watching isLoggedIn to load other Vuex modules.
store.watch(
    state => state.authentication.isLoggedIn,
    isLoggedIn => store.dispatch('load/onLoginChange', isLoggedIn));

export default store;
