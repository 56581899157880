<template>
    <app-data-table
        :class="{ 'app-data-table-editable': canEdit }"
        :items="tableData.items"
        :headers="headers"
        :server-items-length="tableData.total"
        :list-options.sync="options"
        :loading="loading"
        :dynamic-disabled="dynamicDisabled"
        @click:row="viewItem">
        <template v-for="field in fieldKeys" #[`item.${field}`]="{ item }">
            <component
                :is="getCellComponent(field)"
                :key="field"
                :options="getCellOptions(field)"
                :value="item[field]"
                :item="item"
                :class="{ 'clickable': canView }"
                @refresh="refresh" />
        </template>
        <template #[`item.actions`]="{ item }">
            <div class="no-overflow-ellipsis">
                <v-icon
                    v-if="canEdit"
                    fab
                    color="primary"
                    @click.stop="$emit('edit', item)">
                    mdi-pencil-circle
                </v-icon>
                <slot name="extra-actions" :item="item" />
            </div>
        </template>
    </app-data-table>
</template>

<script>
import dataTableMixin from "@/features/schemas/mixins/dataTableMixin";

export default {
    mixins: [ dataTableMixin ],
    types: [
        "default"
    ],
}
</script>

<style lang="scss" scoped>
    .app-data-table-editable::v-deep th:last-child {
        width: 1%;
    }
    .app-data-table-editable::v-deep td:last-child {
        white-space: nowrap;
    }
    .overflow-visible {
        overflow: visible;
    }
</style>
