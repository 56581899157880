let appTableView = () =>
    import(
        /* webpackChunkName: "schemaTableView" */ "@/features/schemas/views/SchemaTableView.vue");

export default [
    {
        path: "/app-installations",
        name: "appInstallations",
        staticProps: { entityKey: "appInstallation" },
    },
    {
        path: "/blocked-companies",
        name: "blockedCompanies",
        staticProps: { entityKey: "blockedCompany" },
    },
    {
        path: "/blocked-workers",
        name: "blockedWorkers",
        staticProps: { entityKey: "blockedWorker" },
    },
    {
        path: "/chat-messages",
        name: "chatMessages",
        staticProps: { entityKey: "chatMessage" },
    },
    {
        path: "/chats",
        name: "chats",
        staticProps: { entityKey: "chat" },
    },
    {
        path: "/companies",
        name: "companies",
        staticProps: { entityKey: "company" },
    },
    {
        path: "/cultures",
        name: "cultures",
        staticProps: { entityKey: "culture" },
    },
    {
        path: "/files",
        name: "files",
        staticProps: { entityKey: "file" },
    },
    {
        path: "/jobs",
        name: "jobs",
        staticProps: { entityKey: "job" },
    },
    {
        path: "/rosters",
        name: "rosters",
        staticProps: { entityKey: "roster" },
    },
    {
        path: "/settings",
        name: "settings",
        staticProps: { entityKey: "setting" },
    },
    {
        path: "/sites",
        name: "sites",
        staticProps: { entityKey: "site" },
    },
    {
        path: "/skill-categories",
        name: "skillCategories",
        staticProps: { entityKey: "skillCategory" },
    },
    {
        path: "/skills",
        name: "skills",
        staticProps: { entityKey: "skill" },
    },
    {
        path: "/team-members",
        name: "teamMembers",
        staticProps: { entityKey: "teamMember" },
    },
    {
        path: "/time-zones",
        name: "timeZones",
        staticProps: { entityKey: "timeZone" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { entityKey: "userRole" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { entityKey: "user" },
    },
    {
        path: "/worker-commutes",
        name: "workerCommutes",
        staticProps: { entityKey: "workerCommute" },
    },
    {
        path: "/worker-rosters",
        name: "workerRosters",
        staticProps: { entityKey: "workerRoster" },
    },
    {
        path: "/worker-skills",
        name: "workerSkills",
        staticProps: { entityKey: "workerSkill" },
    },
    {
        path: "/worker-states",
        name: "workerStates",
        staticProps: { entityKey: "workerState" },
    },
    {
        path: "/worker-swipes",
        name: "workerSwipes",
        staticProps: { entityKey: "workerSwipe" },
    },
    {
        path: "/workers",
        name: "workers",
        staticProps: { entityKey: "worker" },
    }
].map(e => {
    return { ...e, component: appTableView };
});
