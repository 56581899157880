<template>
    <div>
        <!-- TODO: Style this like the bordered inputs -->
        <div>{{ field.title }}</div>
        <div class="d-flex mb-4 file-gap">
            <app-thumbnail
                :file="file"
                @delete="deleteFile" />

            <app-file-drop @input="onInput" />
        </div>
    </div>
</template>

<script>
import formFieldMixin from "./formFieldMixin";
import { get } from "@/features/schemas/services/schemaApi";

export default {
    types: [ "file" ],
    mixins: [formFieldMixin],
    props: {
        value: {
            type: [String, Number],
            default: null
        }
    },
    data() {
        return {
            file: null
        }
    },
    watch: {
        value: {
            immediate: true,
            async handler() {
                if (!this.value) {
                    return;
                }
                await this.getFile();
            }
        }
    },
    methods: {
        async deleteFile(id) {
            await get("file", "delete", { id });
            this.$emit("input", null);
        },
        async getFile() {
            this.file = await get("file", this.value);
        },
        onInput(file) {
            this.$emit("input", file.id);
        }
    },
}
</script>
<style scoped>
.file-gap {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}
</style>
