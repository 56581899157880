
import schemaApi from "@/features/schemas/services/schemaApi";
import axios from "axios";
import { add } from '@/services/apiService';

export default {
    downloadFile,
    deleteFile
};

export async function downloadFile(fileId) {
    let response = await axios
        .get(`/api/Files/Download/${fileId}`, { responseType: 'blob' })

    let blob = new Blob([response.data], { type: response.headers["content-type"] });

    let downloadUrl = window.URL.createObjectURL(blob);
    let link = document.createElement("a");

    if (typeof link.download === "undefined") {
        window.location.href = downloadUrl;
        return;
    }

    link.href = downloadUrl;
    let fileName = getFileName(response.headers['content-disposition']) ?? "file";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
}

export async function uploadFile(fileToUpload) {
    if(!fileToUpload) {
        return;
    }
    let formData = new FormData();
    formData.append("file", fileToUpload);

    let file = await add("files", formData, true)
    return file;
}

function getFileName(disposition) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;

    if (utf8FilenameRegex.test(disposition)) {
        return decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    }

    // prevent ReDos attacks by anchoring the ascii regex to string start and
    // slicing off everything before 'filename='
    const filenameStart = disposition
        .toLowerCase()
        .indexOf('filename=');

    if (filenameStart < 0) {
        return null;
    }

    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    const partialDisposition = disposition.slice(filenameStart);
    const matches = asciiFilenameRegex.exec(partialDisposition);

    return matches && matches[2] ?
        matches[2] :
        null;
}

export async function deleteFile(fileId) {
    await schemaApi.delete("file", fileId);
}

export function toMegabytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
}

export function truncateFileName(fileName) {
    const fileExtension = fileName.split('.').pop();
    const fileNameWithoutExtension = fileName
        .substring(0, fileName.length - fileExtension.length - 1);

    if (fileNameWithoutExtension.length <= 20) {
        return fileName;
    }

    const truncatedName = `${fileNameWithoutExtension.substring(0, 17)}...${fileExtension}`;
    return truncatedName;
}
