<template>
    <div
        ref="wrap"
        :class="{'app-data-table-dynamic-wrap': !dynamicDisabled }">
        <v-data-table
            :class="{ 'app-data-table': true, 'app-data-table-dynamic': !dynamicDisabled }"
            dense
            :dark="isDarkModeEnabled"
            :options.sync="dataTableOptions"
            v-bind="$attrs"
            v-on="$listeners"
            @current-items="onItemsChanged">
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { debounce } from "@/services/debounce";
import { buildListRequest } from "@/services/listRequestBuilder";
import { mapState } from 'vuex';

function isOverflown(element) {
    return !element.classList.contains("no-overflow-ellipsis") && (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth);
}

export default {
    props: {
        dynamicDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dataTableOptions: null,
            boundedResize: null
        };
    },
    computed: {
        wrapElement() {
            return this.$refs["wrap"];
        },
        ...mapState({
            isDarkModeEnabled: s => s.authentication.user.isDarkModeEnabled,
        })
    },
    watch: {
        dataTableOptions: {
            immediate: true,
            handler(value) {
                this.$emit("update:listOptions", buildListRequest(value));
            }
        }
    },
    mounted() {
        if (this.dynamicDisabled) {
            return;
        }
        // Set the items per page to 0 until we've had a chance to calculate them.
        this.dataTableOptions.itemsPerPage = 0;

        // debounce the onResize handler so that quick layout updates don't fire multiple requests.
        const onResize = debounce(this.onResize, 50);

        this.$nextTick(() => {
            this.resizeObserver = new ResizeObserver(onResize);
            this.resizeObserver.observe(this.wrapElement);
            onResize();
        });
    },
    destroyed() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        onResize() {
            if (this.dynamicDisabled) {
                return;
            }

            // The default mobile breakpoint for v-data-table is 600px;
            // We don't want the page sizing to apply when we are less than this.
            if(window.innerWidth < 600) {
                this.dataTableOptions.itemsPerPage = 10;
                return;
            }
            const height = this.wrapElement.clientHeight;
            // Rows are fixed height so we can calculate the items per page and avoid scroll bars.
            // Header is 32 high, rows are 32 high, footer is 37 high.
            const itemsPerPage = Math.floor((height - 32 - 37) / 32);
            this.dataTableOptions.itemsPerPage = itemsPerPage;

            this.$nextTick(() => {
                this.checkOverflow();
            });
        },
        onItemsChanged() {
            if (this.dynamicDisabled) {
                return;
            }
            this.$nextTick(() => {
                this.checkOverflow();
            });
        },
        checkOverflow() {
            if (this.dynamicDisabled || !this.wrapElement) {
                return;
            }

            const selectedCells = this.wrapElement.querySelectorAll("tbody td > *");
            const cells = [...selectedCells].map(c => c.$el ? c.$el : c);

            cells.forEach(cell => {
                // Don't add ellipsis to chips.
                if (cell.querySelector('.v-chip')) {
                    return;
                }
                if (isOverflown(cell)) {
                    cell.classList.add("overflow");
                } else {
                    cell.classList.remove("overflow");
                }
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/style/theme.scss";

.app-data-table-dynamic .overflow {
    position: relative;
    padding-right: 14px;
}

.app-data-table-dynamic .overflow::after {
    content: "…";
    position: absolute;
    right: 8px;
    top: 0;
}
</style>

<style lang="scss" scoped>
@media screen and (min-width: 600px) {
    .app-data-table-dynamic-wrap {
        position: absolute;
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        padding: 0;
    }

    .app-data-table-dynamic {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 0 0 0 12px;
    }

    .app-data-table-dynamic::v-deep .v-data-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .app-data-table-dynamic::v-deep th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 4px !important;
    }

    .app-data-table-dynamic::v-deep .v-data-footer__select {
        display: none;
    }

    .app-data-table-dynamic::v-deep .v-data-footer__pagination {
        margin-left: auto;
    }

    .app-data-table-dynamic::v-deep td {
        padding: 0 4px !important;
        > *:not(.no-overflow-ellipsis) {
            word-break: break-all;
            max-height: 21px;
            max-width: 500px;
            overflow: hidden;

            // TODO: Add ellipsis with attr() hack or
            // https://jsfiddle.net/tturadqq/
            // color: transparent;

            // ::after {
            //     content: attr(title);
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     color: $color-something;
            //     white-space: nowrap;
            //     text-overflow: ellipsis;
            //     overflow: hidden;
            // }
        }
    }

    // Fixes icons causing overflown ellipsis to show unnecessarily.
    .app-data-table-dynamic::v-deep .v-icon.v-icon::after {
        content: none;
    }
}
@media screen and (min-width: 600px) and (max-width: 1999px) {
    .app-data-table-dynamic::v-deep th {
        max-width: 75px;
    }
}
@media screen and (min-width: 2000px) {
    .app-data-table-dynamic::v-deep th {
        max-width: 130px;
    }
}
</style>
