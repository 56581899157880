function getDefaultState() {
    return {
        isDrawerOpen: false
    }
}

const state = getDefaultState;

const getters = {
    isDrawerOpen: (state) => {
        return state.isDrawerOpen;
    }
}

const actions = {
    toggleDrawer({ commit }) {
        commit("toggleDrawer");
    },
    closeDrawer({ commit }) {
        commit("setDrawer", false);
    }
};

const mutations = {
    toggleDrawer(state) {
        state.isDrawerOpen = !state.isDrawerOpen;
    },
    setDrawer(state, status) {
        state.isDrawerOpen = status;
    },
};

export const drawer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
