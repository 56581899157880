import axios from "axios";
import { getEndpoint, getDependants } from "./schemaProvider";
import { toIdLabels } from "./labeller";
import { hasItemPage } from "@/routes/itemRoutes";
import store from "@/store/store";

const pathVariablesRegex = /[^{}]?{([a-zA-Z0-9]+)}/g;

const actions = {
    async get(path, model) {
        const response = await axios.get(path, { params: model });
        return response.data;
    },

    async post(path, model, followLocation = false) {
        const response = await axios.post(path, model ?? null);
        if(followLocation) {
            return await getViaLocationHeader(response);
        }
        return response.data;
    },

    async put(path, model, followLocation = false) {
        const response = await axios.put(path, model ?? null);
        if(followLocation) {
            return await getViaLocationHeader(response);
        }
        return response.data;
    },

    async delete(path) {
        await axios.delete(path);
    }
}

async function getViaLocationHeader(response) {
    const uri = response.headers.location;
    const getResponse = await axios.get(uri);
    return getResponse.data;
}

function getPathVariables(path) {
    const matches = [...path.matchAll(pathVariablesRegex)];
    return matches.map(match => match[1]);
}

function applyPathVariables(path, pathVariables, model) {
    let result = path;
    pathVariables.forEach(v => result = result.replace("{" + v + "}", model[v]));
    return result;
}

function removePathVariables(model, pathVariables) {
    let result = {};

    Object
        .keys(model)
        .filter(key => !pathVariables.includes(key))
        .forEach(key => result[key] = model[key]);

    return result;
}

function hasPolicy(policy) {
    return store.getters["authentication/hasPolicy"](policy);
}

function canDo(entityKey, endpointKey) {
    const endpoint = getEndpoint(entityKey, endpointKey);
    if(!endpoint) {
        return false;
    }

    return endpoint.policies == null || endpoint.policies.every(policy => hasPolicy(policy));
}

export async function call(entityKey, endpoint, model, followLocation = false) {
    let endpointInfo = getEndpoint(entityKey, endpoint);
    let path = endpointInfo.path;

    if(model && !Array.isArray(model)) {
        // Add the variables which go into the path. e.g. /api/buckets/{id} -> /api/buckets/1
        let pathVariables = getPathVariables(path);
        path = applyPathVariables(path, pathVariables, model);

        // Remove those path variables from the model. e.g. to avoid /api/buckets/1?id=1
        model = removePathVariables(model, pathVariables);
    }

    let action = actions[endpointInfo.method];
    return await action(path, model, followLocation);
}

export async function list(entityKey, model) {
    return await call(entityKey, "list", model);
}

export async function idLabels(entityKey, model) {
    let result = await list(entityKey, model);
    return toIdLabels(entityKey, result.items);
}

export async function get(entityKey, id) {
    return await call(entityKey, "get", { id });
}

export async function add(entityKey, model, followLocation = true) {
    return await call(entityKey, "add", model, followLocation);
}

export async function update(entityKey, model, followLocation = true) {
    return await call(entityKey, "update", model, followLocation);
}

export async function save(entityKey, model, followLocation = true) {
    const method = model.id ? update : add;
    return await method(entityKey, model, followLocation);
}

export async function delete_(entityKey, id) {
    return await call(entityKey, "delete", { id });
}

export function canAdd(entityKey) {
    return canDo(entityKey, "add");
}

export function canEdit(entityKey) {
    return canDo(entityKey, "update");
}

export function canDelete(entityKey) {
    return canDo(entityKey, "delete");
}

export function canGet(entityKey) {
    return canDo(entityKey, "get");
}

export function canGetMany(entityKey) {
    return canDo(entityKey, "getMany");
}

export function canList(entityKey) {
    return canDo(entityKey, "list");
}

export function canViewItem(entityKey) {
    return hasItemPage(entityKey) && getDependants(entityKey)
        .some(d => canList(d.entity.key));
}

export default {
    call,
    list,
    idLabels,
    get,
    add,
    update,
    save,
    delete: delete_,
    canAdd,
    canEdit,
    canDelete,
    canGet,
    canList,
    canViewItem
}
