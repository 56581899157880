import { getLocale, getTimeZone } from "@/services/localisation";
import { DateTime } from "luxon"

export const types = [
    "date"
]

export function format(value) {
    if (value == null) {
        return "";
    }

    // All datetime values are in UTC. If you don't want this, use datetimeoffset.
    // For display, we convert UTC dates to the user's local time.
    // Datetime values are sent from the server with the suffix "Z".
    // In the absense of an offset, the date will be interpreted as UTC before converting.
    let dateTime = DateTime
        .fromISO(value, { setZone: true, zone: "utc" }) // setZone will override zone here.
        .setLocale(getLocale())
        .setZone(getTimeZone());

    const format = DateTime.DATE_SHORT;

    return dateTime
        .toLocaleString(format);
}
