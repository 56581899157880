import Vue from "vue";
import Router from "vue-router";
import accountRoutes from "./accountRoutes";
import tableRoutes from "./tableRoutes";
import itemRoutes from "./itemRoutes";
import reportRoutes from "./reportRoutes";
import { parseQuery, stringifyQuery } from "@/services/queryStringService"

Vue.use(Router);

let routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/features/dashboard/Index.vue"),
    },
    {
        path: "/reports",
        name: "reports",
        component: () =>
            import("@/features/reports/views/Reports.vue"),
    },
    {
        path: "*",
        redirect: "/",
    },
];

routes = routes
    .concat(accountRoutes)
    .concat(tableRoutes)
    .concat(itemRoutes)
    .concat(reportRoutes);

// Pass the query string and route parameters as props into all routes.
routes.forEach(addProps);

function addProps(r) {
    r.props = route => ({
        ...route.params,
        ...route.query,
        ...r.staticProps
    });

    r.children?.forEach(addProps);
}

const router = new Router({
    mode: "history",
    parseQuery,
    stringifyQuery,
    routes: routes
});

export default router;
