import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const theme = {
    primary: "#006B3D",
    secondary: "#FF681E",
    tertiary: "#bbbbbb",
    quaternary: "#D1E8DE",
    quinary: "#1974E6",
    accent: "#0dbf66",
    error: "#c52e00"
};

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        dark: false,
        themes: {
            light: theme,
        },
    },
});
