<template>
    <app-text-field
        v-bind="$attrs"
        :rules="[numberRule]"
        v-on="$listeners" />
</template>

<script>
export default {
    data() {
        return {
            numberRule: value => !value || !isNaN(value) || 'Must be a number',
        };
    },
};
</script>
