<template>
    <v-app>
        <template v-if="isLoaded">
            <the-alert />
            <the-app-bar v-if="isLoggedIn" />
            <v-main class="main">
                <router-view class="router-container" />
            </v-main>
            <the-app-navigation-drawer />
        </template>
        <template v-else>
            <div class="loading d-flex align-center justify-center flex-grow-1">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="tertiary" />
            </div>
        </template>
    </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

export default {
    components: {
        theAlert: () => import("@/components/TheAlert"),
        theAppBar: () => import("@/components/TheAppBar"),
        theAppNavigationDrawer: () => import("@/components/TheAppNavigationDrawer")
    },

    computed: {
        ...mapState({
            isLoggedIn: s => s.authentication.isLoggedIn,
            user: s => s.authentication.user,
        }),

        ...mapGetters({
            isLoaded: "load/isLoaded"
        }),
    },

    async mounted() {
        this.$router.onReady(this.onStartup)
    },


    methods: {
        ...mapActions({
            onStartup: "load/onStartup"
        })
    }
};
</script>

<style lang="scss">
    @import "assets/style/theme.scss";
    @import "assets/style/theme.scss";
    @import "assets/style/typography.scss";
    @import "assets/style/layout.scss";
    @import "assets/style/components.scss";
    @import "assets/style/utils.scss";
</style>

<style lang="scss" scoped>
    // v-main dynamically calculates some top padding after the page displays. Here we are
    // hardcoding the results of this calculation. This fixes issues with apex charts, where it
    // calculates the height of the chart before the v-main padding is determined. This also
    // prevents the whole page popping down by 48 pixels.
    .main {
        padding-top: 60px !important;
    }
</style>
