import store from "@/store/store";

function getErrorMessage(e) {
    let data = e.response?.data;

    return data?.message ?? data ?? e;
}

function handleError(e) {
    // eslint-disable-next-line no-console
    console.log(e);

    if (isValidationException(e)) {
        store.dispatch("alert/error", getValidationErrorMessage(e));
        return;
    }

    if(isMultipleErrors(e)) {
        let messages = getMessages(e);
        messages.forEach(message => store.dispatch("alert/error", message));
        return;
    }
    store.dispatch("alert/error", getErrorMessage(e));
}

function getValidationErrorMessage(e) {
    return e.response?.data?.message.split('\n')[0];
}

function isValidationException(e) {
    return e.response?.data?.name == "ValidationException";
}

function isMultipleErrors(e) {
    return !!(e.response?.data?.errors);
}

function getMessages(e) {
    return Object
        .entries(e.response.data.errors)
        .map(([, messages]) => messages)
        .flat();
}

export default {
    getErrorMessage,
    handleError,
};

export {
    getErrorMessage,
    handleError
}
