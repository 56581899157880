<template>
    <div>
        <div>
            {{ truncatedDisplay }}
            <v-icon v-if="value" @click="dialog = true">
                mdi-open-in-new
            </v-icon>
        </div>
        <v-dialog v-model="dialog" width="unset">
            <v-card>
                <v-card-text class="full-content">
                    <div class="pt-4">
                        {{ value }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    types: [
        "verbose"
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        truncatedDisplay() {
            return this.value && this.value.length > 15 ?
                this.value.substring(0, 15) + '...' :
                this.value;
        }
    },
};
</script>
<style scoped>
.full-content {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100%;
}
</style>
